html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
}

#root {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  scrollbar-width: none;
  scrollbar-color: #029efd rgba(255, 255, 255, 1);
}

/* TODO: show scrollbar in peaGroupSelector */
* ::-webkit-scrollbar {
  width: 0;
}

* ::-webkit-scrollbar-track-piece {
  opacity: 0;
}

* ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  background-color: #029efd;
}

* ::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 8px;
}

*:hover,
*:focus {
  outline: none;
  border: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: #096;
}

h1 {
  margin: 0.67em 0;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 150ms ease-in;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

/* TODO: remove when fixed:
https://github.com/ankeetmaini/react-infinite-scroll-component/issues/180 */
.infinite-scroll-component__outerdiv {
  width: 100%;
}

/* to debug layout issues, uncomment this code and use this extension:
https://chrome.google.com/webstore/detail/pesticide-for-chrome/bblbgcheenepgnnajgfpiicnbbdmmooh?hl=en-US */

/* * {
  background-color: rgba(255, 0, 0, 0.2);
}
* * {
  background-color: rgba(0, 255, 0, 0.2);
}
* * * {
  background-color: rgba(0, 0, 255, 0.2);
}
* * * * {
  background-color: rgba(255, 0, 255, 0.2);
}
* * * * * {
  background-color: rgba(0, 255, 255, 0.2);
}
* * * * * * {
  background-color: rgba(255, 255, 0, 0.2);
}
* * * * * * * {
  background-color: rgba(255, 0, 0, 0.2);
}
* * * * * * * * {
  background-color: rgba(0, 255, 0, 0.2);
}
* * * * * * * * * {
  background-color: rgba(0, 0, 255, 0.2);
} */
